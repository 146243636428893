import { ActionDataTypes, ActionDataWidgets } from '../types'
import { CountrySelect, CountryType } from '../../util/CountryCodes'
import { DefaultManager, ManagerProps } from '../components/Manager/Manager'
import { FC, useEffect, useMemo, useState } from 'react'
import { FlowpageLinkCard } from '../../flowpage/FlowpageLinkCard'
import { FlowpageProps } from '../types/FlowpageProps'
import { FormProps, StyledForm } from '../components/Layout'
import { FormTitle } from '../components/Title'
import { ImageEdit } from '../components/ImageEdit'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input/index'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { LinkStyleGated } from '../components/LinkStyle/LinkStyleGated'
import { Message } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/Message'
import { Option, ToggleButtonList } from '@dtx-company/shared-components/src'
import { SettingsAndSaveLink } from '../components/Settings'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { ToggleButton } from '@dtx-company/shared-components/src/components/atoms/ToggleButton/index'
import { getValidatedActionData } from '../typeUtils'
import { stopPropagationEvents } from '../../profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useForm } from 'react-hook-form-deprecated'
import { useLinkThemeState } from '../components/LinkStyle/hooks/useLinkThemeState'
import { usePreviousValue } from '@dtx-company/true-common/src/hooks/usePreviousValue/usePreviousValue'
import { useSetValueWithPreview } from '../utils'
import { useSubmitWidget } from '../submitUtils'
import { v4 as uuid } from 'uuid'
import customValidations, { isValidCSVEmails } from '../../../constants/customValidations'

enum MessageType {
  SMS = 'SMS',
  EMAIL = 'Email',
  WHATSAPP = 'Whatsapp',
  CALL = 'Call'
}

enum FormFields {
  TYPE = 'type',
  NAME = 'name',
  IMAGE = 'image',
  SEND_TO = 'sendTo',
  BODY = 'body',
  SUBJECT = 'subject'
}

export const Form: FC<FormProps> = ({ widgetObj, order, curr, handleClose }: FormProps) => {
  const id = curr ? curr.id : uuid()
  const actionData = getValidatedActionData<'sendMessage'>(curr?.actionData, 'link')
  const [countryCode, setCountryCode] = useState<string | undefined>(
    curr ? actionData?.countryCode : '1'
  )

  const { linkTheme, setLinkTheme } = useLinkThemeState({
    defaultLinkTheme: curr?.linkTheme ?? null
  })
  const { hasOperation } = useAuthState()
  const hasLibertyMutualOnlyLinks = hasOperation('view:liberty_mutual_links_only')
  const defaultMessageType = hasLibertyMutualOnlyLinks ? MessageType.CALL : MessageType.SMS
  const {
    register,
    setValue: setFormValue,
    handleSubmit,
    watch,
    trigger,
    errors,
    clearErrors
  } = useForm({
    defaultValues: {
      type: actionData?.type || defaultMessageType,
      name: curr?.title || '',
      image: curr?.thumbNailImgUrl || '',
      sendTo: actionData?.sendTo || '',
      subject: actionData?.subject || '',
      body: actionData?.body || ''
    }
  })

  const submitWidget = useSubmitWidget()

  const onSubmit = async ({
    type,
    name,
    image,
    sendTo,
    subject,
    body
  }: ActionDataTypes['sendMessage']): Promise<void> => {
    const actionData: ActionDataTypes['sendMessage'] = {
      type,
      name,
      sendTo,
      body,
      link: ''
    }
    const emailMessage = []
    switch (type) {
      /* added ?& to sms link for compatibility with android and iOS - https://flowcode.slack.com/archives/C012L0LKKGX/p1648830247401319?thread_ts=1648826584.278479&cid=C012L0LKKGX */
      case MessageType.SMS:
        actionData.countryCode = countryCode
        const url = new URL(`sms:${countryCode ? '+' + countryCode : ''}${sendTo}`)
        actionData.link = `${url}?&body=${window.encodeURIComponent(body ?? '')}`
        break
      case MessageType.CALL:
        actionData.countryCode = countryCode
        actionData.link = countryCode ? `tel:+${countryCode}${sendTo}` : `tel:${sendTo}`
        break
      case MessageType.EMAIL:
        actionData.subject = subject
        if (subject) emailMessage.push(`subject=${subject}`)
        if (body) emailMessage.push(`body=${encodeURIComponent(body)}`)
        const cleanedSendTo = sendTo
          .split(',')
          .map(email => email.trim())
          .join(',')
        actionData.link = `mailto:${cleanedSendTo}?${emailMessage.join('&')}`
        break
      case MessageType.WHATSAPP:
        actionData.countryCode = countryCode
        actionData.link = `https://api.whatsapp.com/send?phone=+${countryCode}${sendTo}&text=${body}`
        break
    }

    await submitWidget({
      image,
      curr,
      actionData: { ...actionData },
      linkTheme,
      handleClose,
      widgetType: (curr ? curr.type : widgetObj.type) as ActionDataWidgets,
      fields: {
        title: name,
        description: '',
        id,
        order
      }
    })
  }
  const watchAll = watch()
  const isEmail = watchAll.type === MessageType.EMAIL
  const wasEmail = usePreviousValue(isEmail)
  const isCall = watchAll.type === MessageType.CALL
  const isPhoneNum =
    isCall || watchAll.type === MessageType.SMS || watchAll.type === MessageType.WHATSAPP

  useEffect(() => {
    register(FormFields.TYPE)
    register(FormFields.NAME, {
      required: 'Please enter a title'
    })
    register(FormFields.IMAGE)
    register(FormFields.SEND_TO, {
      validate: (value: string) => {
        if (isEmail) {
          return (
            isValidCSVEmails(value) || 'One or more of the emails entered is invalid or incomplete'
          )
        }
        return value.match(customValidations.phone.pattern) || 'Please enter a valid phone number'
      },
      required: `Please enter ${isEmail ? 'at least one email' : 'a phone number'}`
    })
    register(FormFields.BODY, {
      maxLength: {
        value: 160,
        message: "You can't use more than 160 characters"
      }
    })
    register(FormFields.SUBJECT, {
      maxLength: {
        value: 78,
        message: "You can't use more than 78 characters"
      }
    })
  }, [isEmail, register])

  const previewLink = useMemo(
    () => ({
      provider: LinkProvider.WIDGET,
      type: 'sendMessage',
      expand: null,
      __typename: 'Link' as const,
      actionData: actionData || {},
      title: watchAll.name,
      thumbNailImgUrl: watchAll.image,
      id,
      linkTheme,
      order,
      active: true,
      embed: false,
      description: '',
      displayType: 'default'
    }),
    [actionData, id, linkTheme, order, watchAll.image, watchAll.name]
  )
  const disabled = !!(errors?.name || errors?.sendTo)
  const edit = Boolean(curr)
  const { setValue, setLinkThemeValue } = useSetValueWithPreview(
    previewLink,
    setFormValue,
    setLinkTheme
  )
  return (
    <>
      <StyledForm
        onSubmit={handleSubmit(onSubmit)}
        title={<FormTitle icon={<Message />} title={`${edit ? 'Edit this' : 'Add a'} message`} />}
      >
        <ToggleButtonList
          selectedValue={watchAll.type}
          onChange={(_e, type) => {
            setValue(FormFields.TYPE, type)
            if (type === MessageType.EMAIL || wasEmail) {
              setFormValue(FormFields.SEND_TO, '')
              clearErrors(FormFields.SEND_TO)
            }
          }}
          justifyContent="center"
          {...stopPropagationEvents}
        >
          {!hasLibertyMutualOnlyLinks && (
            <ToggleButton
              color={watchAll.type === MessageType.SMS ? 'primary.white' : 'primary.black'}
              label="SMS"
              value="SMS"
            />
          )}
          <ToggleButton
            color={watchAll.type === MessageType.CALL ? 'primary.white' : 'primary.black'}
            label="Call"
            value="Call"
          />
          <ToggleButton
            color={watchAll.type === MessageType.WHATSAPP ? 'primary.white' : 'primary.black'}
            label="Whatsapp"
            value="Whatsapp"
          />
          <ToggleButton
            color={watchAll.type === MessageType.EMAIL ? 'primary.white' : 'primary.black'}
            label="Email"
            value="Email"
          />
        </ToggleButtonList>

        <Input
          label="Title"
          labelProps={{ marginBottom: '8px' }}
          placeholder="Enter a title"
          onChange={e =>
            setValue(FormFields.NAME, e.target.value, {
              shouldValidate: true
            })
          }
          onBlur={() => trigger(FormFields.NAME)}
          value={watchAll.name}
          helperText={errors?.name?.message}
          error={Boolean(errors.name)}
          minWidth="100%"
          type="text"
          {...stopPropagationEvents}
        />

        <ImageEdit
          imageUrl={watchAll.image || '/icons/messages.svg'}
          setValue={setValue}
          defaultImage="/icons/messages.svg"
          id={id}
          active={curr ? curr.active : true}
          {...stopPropagationEvents}
        />

        {isEmail && (
          <>
            <Input
              label="Email(s)"
              labelProps={{ marginBottom: '8px' }}
              placeholder="Enter email address(es)"
              onChange={e =>
                setFormValue(FormFields.SEND_TO, e.target.value, {
                  shouldValidate: true
                })
              }
              onBlur={() => trigger(FormFields.SEND_TO)}
              value={watchAll.sendTo}
              helperText={
                errors?.sendTo?.message || 'If using multiple emails separate with commas(,)'
              }
              type="text"
              maxWidth="100%"
              error={Boolean(errors.sendTo)}
              controlled
              {...stopPropagationEvents}
            />
            <Input
              label="Subject"
              labelProps={{ marginBottom: '8px' }}
              placeholder="Enter a subject"
              onChange={e =>
                setValue(FormFields.SUBJECT, e.target.value, {
                  shouldValidate: true
                })
              }
              onBlur={() => trigger(FormFields.SUBJECT)}
              value={watchAll.subject}
              helperText={errors?.subject?.message}
              error={Boolean(errors.subject)}
              minWidth="100%"
              type="text"
              {...stopPropagationEvents}
            />
          </>
        )}

        {isPhoneNum && (
          <Input
            label="Phone Number"
            labelProps={{ marginBottom: '8px' }}
            placeholder=""
            onChange={e =>
              setFormValue(FormFields.SEND_TO, e.target.value, {
                shouldValidate: true
              })
            }
            onBlur={() => trigger(FormFields.SEND_TO)}
            value={watchAll.sendTo}
            helperText={errors?.sendTo?.message}
            type={'tel'}
            maxWidth="100%"
            error={Boolean(errors.sendTo)}
            controlled
            startAdornment={
              <CountrySelect
                defaultValue={countryCode}
                onChange={(_, option) => {
                  const newCountryCodeTyped = (option as Option).value as CountryType
                  setCountryCode(newCountryCodeTyped?.phone)
                }}
              />
            }
            {...stopPropagationEvents}
          />
        )}

        {!isCall && (
          <Input
            label={isEmail ? 'Body' : 'Message'}
            labelProps={{ marginBottom: '8px' }}
            placeholder={`Enter ${isEmail ? 'body message' : 'a message'}`}
            onChange={e => setValue(FormFields.BODY, e.target.value, { shouldValidate: true })}
            onBlur={() => trigger(FormFields.BODY)}
            value={watchAll.body}
            helperText={errors?.body?.message}
            error={Boolean(errors.body)}
            minWidth="100%"
            type="text"
            multiline
            height="66px"
            {...stopPropagationEvents}
          />
        )}

        <Spacer mb="16px" />
        <LinkStyleGated linkTheme={linkTheme} setLinkTheme={setLinkThemeValue} />

        <Spacer mt="16px" />
        <SettingsAndSaveLink disabled={disabled} curr={curr} handleClose={handleClose} />
      </StyledForm>
    </>
  )
}

export const Flowpage: FC<FlowpageProps> = ({
  link,
  editLinkMode,
  preview,
  isLockedTemplateLink
}: FlowpageProps) => {
  return (
    <FlowpageLinkCard
      link={link}
      isLockedTemplateLink={isLockedTemplateLink}
      widgetId={'message'}
      preview={preview}
      iconSrc={link.thumbNailImgUrl || '/icons/messages.svg'}
      onlyTitle={false}
      editLinkMode={editLinkMode}
    />
  )
}

export const Manager: FC<ManagerProps> = ({ editWidget, link, key, handle }: ManagerProps) => {
  return (
    <DefaultManager
      iconUrl={'/icons/messages.svg'}
      handle={handle}
      editWidget={editWidget}
      link={link}
    />
  )
}
