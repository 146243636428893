const customValidations = {
  phone: {
    pattern: /(^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4}[0-9]?)$)|(^[0-9]{5,11}[0-9]?$)/
  },
  email: {
    pattern: /^[a-zA-Z0-9-_]+([.-/+]?[a-zA-Z0-9-_]+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/
  }
}

export const isValidCSVEmails = (emailsStr: string): boolean => {
  return emailsStr
    .split(',')
    .map(email => email.trim())
    .every((email: string) => email.match(customValidations.email.pattern))
}

export default customValidations
